<template>
	<div>
		<f-default-header
			:title="$t('pages.pos.title')"
			:subtitle="$t('pages.pos.setDefaults.subtitle')" />

		{{ $t("pages.pos.setDefaults.firstTime") }}

		<file-patterns />
		<br />
		<f-set-columns />

		<f-actions-btn-holder>
			<v-btn color="secondary" elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>

			<v-btn color="primary" elevation="0" large @click="setDefaults">
				{{ $t("labels.btnContinue") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FSetColumns from "../../components/pos/ColumnsRelation.vue";
import FilePatterns from "../../components/pos/FilePatterns.vue";
// import { get } from "./../../services/distributors";

export default {
	components: {
		FSetColumns,
		FilePatterns,
	},
	// created() {
	//   console.log(this.$store.state.pos.view.distributorID, "dist")
	//   if (!this.$store.state.pos.view.distributorID) {
	//     this.back();
	//   }
	//   get(this.$store.state.pos.view.distributorID);
	// },
	methods: {
		async setDefaults() {
			const result = await this.$store.dispatch("pos/setHeadersConfig");
			// console.log(result);
			if (result.status) {
				this.$router.push({
					name: "pos-upload-process",
				});
			} else {
				this.$store.commit("snackbar/show", {
					content: this.$t("labels.posSetDefaultError"),
					type: "error",
				});
			}
		},
		back() {
			this.$router.push({
				name: "pos-upload",
			});
		},
	},
};
</script>
