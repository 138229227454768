import { i18n } from "../main";
import NumberFormater from "../helpers/number_formater";

const currencyValidation = (value, isLaam) => {
	const cleanValue = NumberFormater.formatCurrencyByPattern(
		value,
		isLaam ? "usd" : "brl"
	);
	if (cleanValue == 0) {
		return i18n.t("validation.priceZero");
	}

	return "";
};

export default {
	provider: {
		isVisible: { br: true, laam: false },
		isRequired: { br: true, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	branchId: {
		isVisible: { br: false, laam: true }, // Branch Code / ID
		isRequired: { br: false, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	city: {
		isVisible: { br: true, laam: true }, // Ship-to City: laam | Cidade (end user) : br
		isRequired: { br: true, laam: { master: false, direct: true } },
		value: "",
		validation: (value) => {
			if (!value) {
				return i18n.t("validation.required");
			}

			return "";
		},
	},
	state: {
		isVisible: { br: true, laam: true }, // Ship-to State ( abbrev ) : laam | Estado (end user) : br
		isRequired: { br: true, laam: { master: false, direct: true } },
		value: "",
		validation: (value) => {
			if (!value || value.length !== 2 || !isNaN(value)) {
				return i18n.t("validation.validateState");
			}

			return "";
		},
	},
	country: {
		isVisible: { br: false, laam: true }, // End Cust ISO Country Code
		isRequired: { br: false, laam: { master: true, direct: true } },
		value: "",
		validation: (value) => "",
	},
	postalCode: {
		isVisible: { br: false, laam: true }, // Ship-to postal / zip code
		isRequired: { br: false, laam: { master: true, direct: true } },
		value: "",
		validation: (value) => "",
	},
	invoiceDate: {
		isVisible: { br: false, laam: true }, // Invoice Date
		isRequired: { br: false, laam: { master: true, direct: true } },
		value: "",
		validation: (value) => "",
	},
	invoiceNumber: {
		isVisible: { br: false, laam: true }, // Invoice Number
		isRequired: { br: false, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	partNumber: {
		isVisible: { br: true, laam: true }, // Fluke Model or UPC Code : laam | Part Number / EAN code : br
		isRequired: { br: true, laam: { master: true, direct: true } },
		value: "",
		validation: (value, isLaam) => {
			if (!value) {
				return i18n.t("validation.invalidNumber");
			}

			if (!isLaam && /[^0-9]/.test(value)) {
				return i18n.t("validation.invalidNumber");
			}

			return "";
		},
	},
	quantity: {
		isVisible: { br: true, laam: true }, // Qty Sold : laam | Quantidade : br
		isRequired: { br: true, laam: { master: true, direct: true } },
		value: "",
		validation: (value) => {
			if (!value || /[^0-9]/.test(value)) {
				return i18n.t("validation.invalidNumber");
			}

			if (+value == 0) {
				return i18n.t("validation.quantityZero");
			}

			return "";
		},
	},
	price: {
		isVisible: { br: true, laam: true }, // Unit Cost : laam | Preço unitário de venda bruto reportado : br
		isRequired: { br: false, laam: { master: true, direct: true } },
		value: "",
		validation: currencyValidation,
	},
	unitResale: {
		isVisible: { br: false, laam: true }, // Unit Resale
		isRequired: { br: false, laam: { master: true, direct: true } },
		value: "",
		validation: currencyValidation,
	},
	extendedResale: {
		isVisible: { br: false, laam: true }, // Extended Resale
		isRequired: { br: false, laam: { master: true, direct: true } },
		value: "",
		validation: currencyValidation,
	},
	currencyCode: {
		isVisible: { br: false, laam: true }, // Currency Code
		isRequired: { br: false, laam: { master: true, direct: true } },
		value: "",
		validation: (value) => "",
	},
	shipmentDate: {
		isVisible: { br: true, laam: false },
		isRequired: { br: true, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => {
			let date;
			try {
				date = new Date(value);

				if ("Invalid Date" == date) {
					throw new Error("Invalid Date");
				}
			} catch (e) {
				return i18n.t("validation.invalidDate");
			}

			if (!value || value.length !== 10) {
				return i18n.t("validation.invalidDate");
			}

			return "";
		},
	},
	endUser: {
		isVisible: { br: true, laam: true }, // Ship-to (end user) Customer Name : laam | Nome (end user) : br
		isRequired: { br: false, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	endUserNumber: {
		isVisible: { br: false, laam: true }, // Ship-to Customer Number
		isRequired: { br: false, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	endUserOrder: {
		isVisible: { br: false, laam: true }, // Ship-to Customer Order #
		isRequired: { br: false, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	endUserInvoiceDate: {
		isVisible: { br: false, laam: true }, // Ship / Invoice Date
		isRequired: { br: false, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	endCountryCode: {
		isVisible: { br: false, laam: true }, // Country Name (if not US)
		isRequired: { br: false, laam: { master: false, direct: true } },
		value: "",
		validation: (value) => "",
	},
	endUserVerticalMarket: {
		isVisible: { br: false, laam: true }, // Ship-to (end user) Customer Vertical Market
		isRequired: { br: false, laam: { master: false, direct: true } },
		value: "",
		validation: (value) => "",
	},
	activityCnae: {
		isVisible: { br: true, laam: false },
		isRequired: { br: true, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	distributorName: {
		isVisible: { br: true, laam: false },
		isRequired: { br: true, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	distributorCnpj: {
		isVisible: { br: true, laam: false },
		isRequired: { br: true, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => {
			if (value.replace(/[^0-9]/g, "").length != 14) {
				return i18n.t("validation.invalidCNPJ");
			}

			return "";
		},
	},
	resellerName: {
		isVisible: { br: false, laam: true }, // Reseller Name
		isRequired: { br: false, laam: { master: true, direct: true } },
		value: "",
		validation: (value) => "",
	},
	distyCountryCode: {
		isVisible: { br: false, laam: true }, // Disty ISO Country Code
		isRequired: { br: false, laam: { master: true, direct: true } },
		value: "",
		validation: (value) => "",
	},
	flukeModelNoun: {
		isVisible: { br: false, laam: true }, // Fluke Model / Noun
		isRequired: { br: false, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	sicCode: {
		isVisible: { br: false, laam: true }, // SIC Code
		isRequired: { br: false, laam: { master: false, direct: false } },
		value: "",
		validation: (value) => "",
	},
	// validation only
	total: {
		isVisible: { br: false, laam: false },
		isRequired: { br: false, laam: { master: false, direct: false } },
		value: "",
		validation: currencyValidation,
	}
};
