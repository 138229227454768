<template>
	<f-card v-if="loaded">
		<v-row>
			<v-col cols="12">
				<f-card-title>
					{{ $t("pages.pos.new.columnsRelation.definition") }}
				</f-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" v-if="loading">
				<v-progress-linear indeterminate height="12" />
			</v-col>
			<v-col cols="12" v-else>
				<table>
					<tbody>
						<tr v-for="item in optionsList" :key="item" class="with-hover">
							<td
								style="padding-right: 10px"
								:class="{ 'column-bold': isRequired(item) }">
								{{ $t(`pages.pos.tableHeaders.${distRegion}.${item}`) }}
								{{ isRequired(item) ? "*" : "" }}
							</td>
							<td style="width: 85%">
								<v-select
									v-model="options[item]['value']"
									outlined
									dense
									clearable
									hide-details="auto"
									:items="headers"
									@change="select(item, options[item]['value'])"
									:label="$t('labels.selectColumn')">
									<template v-slot:selection="{ item: curr }">
										{{ curr.text }}
									</template>
								</v-select>
							</td>
						</tr>
					</tbody>
				</table>
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
import { get } from "./../../services/distributors";
import posColumnsInfo from "../../data/posColumnsInfo";
// import {snakeToCamel } from "../../helpers/string_utils"

export default {
	data: () => ({
		headers: [],
		options: posColumnsInfo,
		loading: true,
		loaded: false,
		distConf: {
			br: false,
			laam: {
				direct: false,
				master: false,
			},
		},
	}),
	computed: {
		optionsList() {
			return Object.keys(this.options).filter((item) => this.isVisible(item));
		},
		file() {
			return this.$store.state.pos.view.file;
		},
		distRegion() {
			return this.$store.state.distributor.view.region === "laam"
				? "laam"
				: "br";
		},
	},
	created() {
		this.getData();
	},
	methods: {
		select(item, value) {
			if (value === " " && this.isRequired(item)) {
				let column = this.$t(`pages.pos.columnsInfo.${item}`);
				this.$store.commit("snackbar/show", {
					content: this.$t("snackbar.posErrorColumn", { column: column }),
					type: "error",
				});
				this.$nextTick(() => {
					this.options[item]["value"] = null;
				});
				return;
			}

			// this.$store.commit("snackbar/show", {
			// 	content:
			// 		"Por favor selecione outro valor para a coluna " +
			// 		this.$t(`labels.${item}`),
			// 	type: "error",
			// });
			this.$nextTick(() => {
				var selecteds = [];
				const headers = {};
				const props = Object.keys(this.options);
				props.forEach((item) => {
					if (this.options[item].value) {
						selecteds.push(this.options[item].value);
						headers[item] = this.options[item].value;
					}
				});

				this.headers.forEach((item) => {
					if (item.value !== " ") {
						item.disabled = selecteds.indexOf(item.value) !== -1;
					}
				});

				this.$store.state.pos.view.config.headers = headers;
			});
		},
		isVisible(item) {
			return this.distConf.br
				? this.options[item].isVisible.br
				: this.options[item].isVisible.laam;
		},
		isRequired(item) {
			if (this.distConf.br) {
				return this.options[item].isRequired.br;
			} else {
				return this.distConf.laam.master
					? this.options[item].isRequired.laam.master
					: this.options[item].isRequired.laam.direct;
			}
		},
		async getData() {
			this.readFileHeaders();
			if (!this.$store.state.pos.view.distributorID) {
				this.back();
			}
			await get(this.$store.state.pos.view.distributorID);
			this.getDistConfig();
		},
		getDistConfig() {
			const distributor = this.$store.state.distributor.view;

			if (!distributor.region || distributor.region === "br") {
				this.distConf.br = true;
			} else {
				if (+distributor.master) {
					this.distConf.laam.master = true;
				} else {
					this.distConf.laam.direct = true;
				}
			}

			this.loaded = true;
		},
		async readFileHeaders() {
			this.loading = true;
			const result = await this.$store.dispatch("pos/readFileHeaders");
			if (result.length < 2) {
				this.$store.commit("snackbar/show", {
					content: this.$t("pages.pos.new.error.insufficientNumberOfColumns"),
					type: "error",
				});
				this.back();
				this.loading = false;
				return;
			}

			this.loading = false;

			this.headers = result.map((item) => ({
				text: item,
				value: item,
				disabled: false,
			}));
			this.headers.push({
				text: this.$t("labels.noRelation"),
				value: " ",
				disabled: false,
			});
		},
		back() {
			this.$router.push({
				name: "pos-upload",
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.column-bold {
	font-weight: bold;
}
td {
	/* line-height: 50px; */
}
.with-hover:hover {
	background-color: #f5f5f5;
}
</style>
