<template>
	<f-card>
		<v-row>
			<v-col cols="12"></v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<f-card-title>
					{{ $t("labels.currencyPattern") }}
				</f-card-title>

				<v-radio-group v-model="pattern" mandatory>
					<v-radio label="1.000,00" value="brl" />
					<v-radio label="1,000.00" value="usd" />
				</v-radio-group>
			</v-col>

			<v-col cols="6">
				<f-card-title>
					{{ $t("labels.datePattern") }}
				</f-card-title>
				<v-radio-group v-model="date" mandatory>
					<v-radio label="DD/MM/YYYY" value="dmy" />
					<v-radio label="MM/DD/YYYY" value="mdy" />
					<v-radio label="YYYY/MM/DD" value="ymd" />
				</v-radio-group>
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
export default {
	computed: {
		pattern: {
			get() {
				return this.$store.state.pos.view.config.currencyPattern;
			},
			set(value) {
				this.$store.commit("pos/updateCurrencyPattern", value);
			},
		},
		date: {
			get() {
				return this.$store.state.pos.view.config.datePattern;
			},
			set(value) {
				this.$store.commit("pos/updateDatePattern", value);
			},
		},
	},
};
</script>
